
























































import { computed, defineComponent, PropType } from '@vue/composition-api';
// @ts-ignore
import * as colors from '@fc/angie-ui/dist/styles/_js_exports.scss';
import { Video } from '@fc/angie-ui';
import { VideoPlayback } from '@fc/angie-ui/src/components/Video/types';
import { Position, BGColor } from './types';
import { useVideoMediaEffect } from './useVideoMediaEffect';

export default defineComponent({
  name: 'MediaBackground',
  inheritAttrs: false,
  components: {
    Video,
    FCImage: () => import('~/components/FCImage.vue'),
  },
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    firstFrameImageUrl: {
      type: String,
      default: '',
    },
    firstFrameImageAlt: {
      type: String,
      default: '',
    },
    webmVideo: {
      type: String,
      default: '',
    },
    mp4Video: {
      type: String,
      default: '',
    },
    webmMobileVideo: {
      type: String,
      default: '',
    },
    mp4MobileVideo: {
      type: String,
      default: '',
    },
    mobileVideoPlayback: {
      type: String as PropType<VideoPlayback>,
      default: VideoPlayback.auto,
    },
    tabletUpVideoPlayback: {
      type: String as PropType<VideoPlayback>,
      default: VideoPlayback.auto,
    },
    mediaPosition: {
      type: String as PropType<Position>,
      default: Position.center,
      validator: (mediaPosition: Position) => !!Position[mediaPosition],
    },
    backgroundColor: {
      type: String,
      default: BGColor.white,
      validator: (backgroundColor: BGColor) => !!BGColor[backgroundColor],
    },
    isFullWidth: {
      type: Boolean,
      default: true,
    },
    videoComponent: {
      type: Object,
      default: () => ({}),
    },
    pictureResponsiveComponent: {
      type: Object,
      default: () => ({}),
    },
    brightness: {
      type: String,
      default: '1',
    },
  },
  setup(props, context) {
    const {
      imageUrl,
      showVideo,
      videoKey,
      videoPlayback,
    } = useVideoMediaEffect({
      sources: {
        firstFrameImageUrl: props.firstFrameImageUrl,
        mp4MobileVideo: props.mp4MobileVideo,
        mp4Video: props.mp4Video,
        webmMobileVideo: props.webmMobileVideo,
        webmVideo: props.webmVideo,
        mobileVideoPlayback: props.mobileVideoPlayback,
        tabletUpVideoPlayback: props.tabletUpVideoPlayback,
      },
      context,
    });

    const displayClasses = computed(() => {
      if (props.webmMobileVideo || props.mp4MobileVideo) {
        return 'fc-hidden md:fc-flex';
      }
      return 'fc-flex';
    });

    // @ts-ignore
    const { $device } = useNuxtApp();

    return {
      isVideo: computed(() => props.imageSrc == null || props.imageSrc === '' || !!videoKey.value.length),
      imageStyles: computed((): Partial<CSSStyleDeclaration> => ({
        backgroundColor: colors[props.backgroundColor] ?? '',
        backgroundSize: props.isFullWidth ? 'cover' : '',
        filter: `brightness(${props.brightness})`,
      })),
      imageUrl,
      showVideo,
      videoPlayback,
      videoKey,
      displayClasses,
      isSafari: computed(() => $device.isSafari),
    };
  },
});
